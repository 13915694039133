import Home from './Components/Pages/Home';
import AboutUs from './Components/Pages/AboutUs';
import WhatWeOffer from './Components/Pages/WhatWeOffer';
import InvestmentProducts from './Components/Pages/InvestmentProducts';
import WhyUs from './Components/Pages/WhyUs';
import Blog from './Components/Pages/Blog';
import Career from './Components/Pages/Career';
import ContactUs from './Components/Pages/ContactUs';
import './Components/Sass/main.scss'
import './Components/Sass/media.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy';
import Disclaimer from './Components/Pages/Disclaimer';
import BlogslistView from './Components/AdminPanel/Blog/blogslist'
import CreateBlog from './Components/AdminPanel/Blog/create'
import Login from './Components/AdminPanel/Login';
import Admin from './Components/AdminPanel/Admin';
import OpportunitiesForStudents from './Components/AdminPanel/Career/OpportunitiesForStudents';
import OpportunitiesForProfessionals from './Components/AdminPanel/Career/OpportunitiesForProfessionals';
import BlogDetails from './Components/Pages/BlogDetails';
import CareerForStudents from './Components/Pages/Career/CareerForStudents';
import CareerForProfessionals from './Components/Pages/Career/CareerForProfessionals';
import EditStudentJobs from './Components/AdminPanel/Career/EditStudentJobs';
import EditProfessionalJobs from './Components/AdminPanel/Career/EditProfessionalJobs';
import ContactDB from './Components/AdminPanel/Database/ContactDB';
import ResetPassword from './Components/AdminPanel/ResetPassword';
import JobStudentsDB from './Components/AdminPanel/Database/JobStudentsDB';
import JobProfessionalsDB from './Components/AdminPanel/Database/JobProfessionalsDB';
import BlogCategories from './Components/AdminPanel/Blog/BlogCategories';
import BlogEdit from './Components/AdminPanel/Blog/edit'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/what-we-offer' element={<WhatWeOffer />} />
          <Route path='/investment-products' element={<InvestmentProducts />} />
          <Route path='/why-us' element={<WhyUs />} />
          <Route path='/career' element={<Career />} />
          <Route path='/career-for-students' element={<CareerForStudents />} />
          <Route path='/career-for-professionals' element={<CareerForProfessionals />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/disclaimer' element={<Disclaimer />} />

          {/* blog */}
          <Route path="/show/:id" element={<BlogDetails />} />
          <Route path='/blog' element={<Blog />} />

          {/* Admin */}
          <Route exact path="/admin-login" element={<Login />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/contact-form-database" element={<ContactDB />} />
          <Route exact path="/job-form-students-database" element={<JobStudentsDB />} />
          <Route exact path="/job-form-professionals-database" element={<JobProfessionalsDB />} />
          <Route exact path="/admin-reset-password" element={<ResetPassword />} />

          {/* blog */}
          <Route path="/bloglist" element={<BlogslistView />} />
          <Route path="/create/" element={<CreateBlog />} />
          <Route path='/blog-categories' element={<BlogCategories />} />
          <Route path="/edit-blog/:id" element={<BlogEdit/>}/>

          {/* Career */}
          <Route path="/career/opportunities-for-students" element={<OpportunitiesForStudents />} />
          <Route path="/career/opportunities-for-professionals" element={<OpportunitiesForProfessionals />} />
          <Route path="/career/edit-student-jobs" element={<EditStudentJobs />} />
          <Route path="/career/edit-professional-jobs" element={<EditProfessionalJobs />} />
          {/* Admin Ends*/}

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
