import React, {useEffect} from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { Helmet } from 'react-helmet'


const Disclaimer = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Header />
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="canonical" href="https://www.wisefinserv.com/disclaimer" />
            </Helmet>
            <div className="disclaimer">
                <div className="page-title">
                    <h4>Disclaimer</h4>
                </div>
                <div className="disclaimer-body">
                    <p>Wise Finserv team has, to the best of its ability, taken into account various factors – both quantitative measures and qualitative assessments, in an unbiased manner, while advising the fund(s). However, they carry unknown risks and uncertainties linked to broad markets, as well as analysts’ expectations about future events. They shoudn't, therefore, be the sole basis of investment decisions.The recommendations and reviews do not guarantee fund performance, nor should they be viewed as an assessment of a fund’s, or the fund’s underlying securities’ creditworthiness.</p>
                    <p>Mutual fund investments are subject to market risks. Please read the scheme information and other related documents before investing. Past performance is not indicative of future returns. Please consider your specific investment requirements before choosing a fund, or designing a portfolio that suits your needs.
                        Wise Finserv (ARN-106923) makes no warranties or representations, express or implied, on products offered through the platform. It accepts no liability for any damages or losses, however caused, in connection with the use of, or on the reliance of its product or related services.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Disclaimer
